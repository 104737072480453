/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root 
{
  --radiusBorder:4px;
  --lightPrimary: rgb(79, 101, 125);
  --primary: hsl(211, 23%, 30%);
  --primaryHover:rgb(51, 66, 82);
  --primaryActive: hsl(210, 24%, 22%);
  --secondaryLight:rgb(248, 118, 98);
  --secondary:#f75840;
  --secondaryHover:hsl(8, 92%, 58%);
  --secondaryActive:hsl(8, 92%, 55%);
  --test: rgb(49, 175, 137);
  --lightColor:#fff;
  --favoriteNotChecked:hsl(0, 7%, 85%);
  --favoriteNotCheckedHover:hsl(39, 100%, 70%);
  --favoriteChecked:hsl(39, 100%, 50%);
  --lightGrey:hsl(0, 7%, 97%);
  --lightGreyFooter:hsl(0, 7%, 97%);
  --lighterGrey:hsl(0, 7%, 90%);
  --grey:hsl(0, 7%, 85%);
  // --darkGrey:#382F2E;
  // --darkGreyHover:#302827;
  // --darkGreyActive:#272120;
  --darkGrey:hsl(10, 6%, 20%);
  --darkGreyHover:hsl(10, 6%, 17%);
  --darkGreyActive:hsl(0, 6%, 14%);
  --smallFontSize:0.9em;
  --tinyFontSize:0.7em;
  --normalFontSize:1em;
  --bigFontSize:1.3em;
  --biggerFontSize:1.6em;
  --hugeFontSize:2.3rem;
  --normalLineHeigth:150%;
  // --mainFont:Poppins;
  --mainFont:'Roboto', sans-serif;;
}

html {
  background-color: var(--lightColor);
}

html[class='dark-mode'] {
  --lightGrey:hsl(0, 7%, 90%);
  filter: invert(1) hue-rotate(180deg);
  -webkit-filter: invert(1) rotate(180deg);
}

@-moz-document url-prefix() {
  html[class='dark-mode'] {
  background-color: black;
  }
}

html[class='dark-mode'] img, html[class='dark-mode'] iframe, html[class='dark-mode'] .unDrawSVG, html[class='dark-mode'] .favorite{
    filter: invert(1) hue-rotate(180deg);
    -webkit-filter: invert(1) rotate(180deg);
    --favoriteNotChecked:hsl(0, 7%, 35%);
    --favoriteNotCheckedHover:hsl(39, 100%, 60%);
}

html[class='dark-mode'] .matchInfo, html[class='dark-mode'] .pepite-card{
  --colorText:black;
}

html[class='dark-mode'] .pastMatch{
  filter: grayscale(100%) invert(1);
}

html[class='light-mode'] .pastMatch {
  filter: grayscale(100%);
}

html[class='dark-mode'] .matchInfo::before{
  -webkit-filter: invert(1) rotate(180deg);
  filter: invert(1) hue-rotate(180deg);
}

a{
  color:inherit;
  text-decoration: none;
  &:hover{
    color:inherit;
    text-decoration: none;
  }
}

h1, h2, h4, h5{
  color:var(--darkGrey);
  font-family: var(--mainFont);
  text-align: center;
}

h2{
  font-size: var(--hugeFontSize);
  font-weight:500;
  margin:0.5rem 0.8rem;
}

h4{
  font-size: var(--biggerFontSize);
  font-weight:500;
  text-align: center;
  margin:0.2rem 0.5rem;
}


h5{
  font-size: var(--bigFontSize);
  font-weight:500;
  text-align: center;
  margin:0.2rem 0.4rem;
}

.textPrimary{
  color:var(--primary)
}

.textSecondary{
  color:var(--secondary)
}

.tiny{
  font-size: var(--tinyFontSize);
}

.my-scrollbar {
  --scrollbar-size: 6px !important;
  --scrollbar-thumb-color: var(--lighterGrey) !important;
  --scrollbar-thumb-hover-color: var(--grey) !important;
  --scrollbar-border-radius: 3px !important;
  right: -11px !important;
  --scrollbar-padding: 0px !important;
  // border: 1px solid gray;
}

body::-webkit-scrollbar {
  display: none;
}

body{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.superMainComponent::-webkit-scrollbar {
  display: none;
}

.superMainComponent{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.logo{
	outline: none;
  border-style:none;
  border: none;
}

p{
  // font-family: Poppins;
  font-family: 'Roboto', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: var(--normalFontSize);
  line-height: var(--normalLineHeigth);
  text-align: justify;
  color:var(--darkGrey);
  margin:1rem 0rem;
  &.smallP{
    font-size: var(--smallFontSize);
  }
  &.normalP {
    font-weight: normal;
  }
  &.boldP{
    font-weight: 500;
  }
  &.centerP{
    text-align: center;
  }
  &.secondaryP{
    color:var(--secondary)
  }
}



.btnPerso{
  font-family: var(--mainFont);
  font-size:var(--bigFontSize);
	border-radius:5px;
	-webkit-transition: background-color .2s ease;
 	transition: background-color .2s ease;
	outline: none;
  border-style:none;
  border: none;
	padding:0.35rem 0.9rem;
  box-sizing: border-box;
  &:hover{
    cursor:pointer;
  }
  &.btnSmall {
    font-size:var(--normalFontSize);
    padding:0.2rem 0.9rem;
  }
  &.btnPrimary{
    background-color:var(--primary);
    color:var(--lightColor);
    &:hover{
      background-color:var(--primaryHover);
    }
    &:active{
      background-color: var(--primaryActive);
    }
  }
  &.btnPrimaryLight{
    background-color:var(--lightColor);
    color:var(--primary);
    border-style:solid;
    border-width: 1px;
    &:hover{
      background-color:var(--primary);
      color:var(--lightColor);
    }
    &:active{
      background-color: var(--primaryHover);
      color:var(--lightColor);
    }
  }
  &.btnSecondary{
    background-color:var(--secondary);
    color:var(--lightColor);
    &:hover{
      background-color:var(--secondaryHover);
    }
    &:active{
      background-color: var(--secondaryActive);
    }
  }
  &.btnSecondaryLight{
    background-color:var(--lightColor);
    color:var(--secondary);
    border-style:solid;
    border-width: 1px;
    &:hover{
      background-color:var(--secondary);
      color:var(--lightColor);
    }
    &:active{
      background-color: var(--secondaryHover);
      color:var(--lightColor);
    }
  }
  &.btnGrey{
    background-color:var(--darkGrey);
    color:var(--lightColor);
    &:hover{
      background-color:var(--darkGreyHover);
    }
    &:active{
      background-color: var(--darkGreyActive);
    }
  }
  &.btnGreyLight{
    background-color:var(--lightColor);
    color:var(--darkGrey);
    border-style:solid;
    border-width: 1px;
    &:hover{
      background-color:var(--darkGrey);
      color:var(--lightColor);
    }
    &:active{
      background-color: var(--darkGreyHover);
      color:var(--lightColor);
    }
  }
  &.leftButton{
    margin-right:0.7rem;
  }
}

:is(.btnPrimary, .btnSecondary, .btnGrey) path {
    fill:var(--lightColor);
}

.btnPrimaryLight path {
  fill:var(--primary);
}
.btnSecondaryLight path {
  fill:var(--secondary);
}
.btnGreyLight path {
fill:var(--lightGrey);
}

.btnSmall.btnPrimary, .btnSmall.btnSecondary, .btnSmall.btnGrey {
  padding:calc(0.2rem + 1px) 0.9rem;
}

.btnPerso.btnPrimary, .btnPerso.btnSecondary, .btnPerso.btnGrey {
  // padding:calc(0.35rem + 1px) 0.9rem;
}

.prefix{
  background-color: var(--primary);
  border-top-left-radius: var(--radiusBorder);
  border-bottom-left-radius: var(--radiusBorder);
  border-color: var(--primary);
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
}

.prefixText, .suffixText{
  color:var(--lightColor);
  font-family: var(--mainFont);
}

.userInput{
  border-top-right-radius: var(--radiusBorder);
  border-bottom-right-radius: var(--radiusBorder);
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-style: solid;
  border-color: var(--primary);
  border-width: 1px;
  outline:none;
  padding-left: 0.4rem;
  font-family: var(--mainFont);
  font-size: var(--normalFontSize);
  &:focus{
      border-width: 2px;
      border-color: var(--secondary);
  }
}

.dropzone { 
  margin-top:3rem;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  min-width: 80vw;
  font-weight: 200;
  height: 300px;
  border: 2px dashed var(--secondary);
  border-radius: 5px;
  background: white;
  margin: 10px 0;

  &.hovering {
      border: 2px solid var(--secondary);
      color: var(--lightGrey) !important;
  }

  .file-label {
      font-size: 1.2em;
  }
}

  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 3.3rem;
  height: 2.1rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.8rem;
  width: 1.8rem;
  left: 0.2rem;
  bottom: 0.15rem;
  background-color: var(--lightColor);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--secondary)
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--secondary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.1rem);
  -ms-transform: translateX(1.1rem);
  transform: translateX(1.1rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

body {
  padding: 0;
  margin: 0;
}

//max-width = screen that are xxx or LESS
//min-width = screen that are xxx or MORE
@media screen and (max-width: 850px) {
  body {
      padding: 0;
      margin: 0;
  }
  .my-scrollbar {
    right: 0 !important;
  }
}
